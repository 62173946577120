& {
    // background-image: url('../../../public/assets/img/page_head.jpg');
    padding: 30px 20px;
    padding-top: 20px;
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 -20px;
    margin-top: 20px;
    background-position: center;

    #Title {
        font-size: 25px;
        padding-bottom: 5px;
        color: #161616;
    }

    #Breadcrumb {
        p {
            font-size: 14px;
            display: flex;
            align-items: center;
            font-weight: 400;
            color: #474747;

            &::after {
                content: '';
                background-image: url('../../../public/assets/icons/breadcrumb-arrow.svg');
                width: 23px;
                display: inline-block;
                height: 23px;
                background-size: contain;
                background-repeat: no-repeat;
                margin: 0;
            }

            &:last-of-type::after {
                display: none;
            }
        }
    }
}