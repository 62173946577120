span#LabelValue {
    display: inline-flex;
    flex-direction: column;
    gap: 4px;

    a.hyperlink {
        p {
            color: #1937ff;
            text-decoration: underline;
        }
    }

    p {
        margin: 0;
        display: inline-block;
        line-height: 18px;
        color: #161616;
        word-wrap: break-word;
        word-break: break-word;

        &.key {
            color: #161616;
            font-size: 14px;
        }

        .label_icon {
            width: 20px;
            margin-right: 10px;

            &.clickable {
                cursor: pointer;
            }
        }

        &.value {
            white-space: pre-line;
            justify-content: flex-start;
            align-items: center;
            font-size: 15px;
            display: inline-flex;
            gap: 8px;
            font-size: 14px;
            width: fit-content;

            &.cl {
                cursor: pointer;
            }

            &.reverse {
                flex-direction: row-reverse;
            }

            img.icon {
                height: 20px;

                &.cl {
                    cursor: pointer;
                }
            }

            &:empty {
                display: none;
            }
        }
    }

    small.error {
        color: red;
    }
}