&._1 {
    background-color: #fff;
    box-shadow: 0px 10px 20px #0000000D;
    border-radius: 8px;
    padding: 40px;
    margin-bottom: 30px;

    &.__1 {
        padding: 0;
    }
}