span#Actions {
    display: inline-flex;
    gap: 5px;
    margin-left: 10px;

    img {
        cursor: pointer;
        padding: 3px;
        width: 20px;
        height: 20px;
        box-sizing: content-box;
        object-fit: contain;
        filter: brightness(0) saturate(100%) invert(42%) sepia(99%) saturate(2%) hue-rotate(324deg) brightness(90%) contrast(90%);
    }
}