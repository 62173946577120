& {
    --main_bg: #eff1f5;

    .css_oth {
        background-color: #ffffff00;
        backdrop-filter: blur(8px);

        #AppLoader {
            img {
                width: 60px;
            }
        }

        #NetworkLost {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            button {
                background-color: gainsboro;
                border: none;
                border-radius: 50px;
                padding: 3px 20px;
            }
        }

        #_Loader {
            --Loader_color: var(--primary-color);
            --Loader_thickness: 5px;
            --Loader_size: 40px;
        }
    }
}