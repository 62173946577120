&._1 {
    label {
        background-color: inherit;
        border: 1px solid var(--primary-color);
        color: var(--secondary-color);
        border-radius: 8px;
        font-weight: 500;
        font-size: 14px;
    }

    .icon {
        width: 30px;
    }
}

&._2 {
    .label {
        padding-bottom: 7px;
        font-size: 14px;
        font-weight: 400;
    }

    label {
        border: 2px dashed gainsboro;
        border-radius: 8px;
        padding: 70px;

        .icon {
            width: 29px;
        }
    }

    // #Files{
    //     .content{
    //         span{
    //             margin-left: auto;
    //             align-self: flex-start;
    //         }
    //     }
    // }
}

&._3 {
    .label {
        margin-bottom: 10px;
    }

    label {
        background-color: #eee;
        border: 1px dashed;
        height: 50px;
        width: 50px;
    }

    img.lim_item {
        border-radius: 5px;
    }
}

&._4 {
    label {
        height: 45px;
        width: 150px;
        border-radius: 8px;
    }
}