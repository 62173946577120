#Button {
    --btn_bg: #eee;
    --btn_border: none;

    background-color: var(--btn_bg);
    border: var(--btn_border);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    padding: 3px 10px;
    text-transform: capitalize;
    text-wrap: nowrap;
    outline: none;
    width: var(--btn_width);
    height: var(--btn_height);
    border-radius: var(--btn_border_radius);
    color: var(--btn_color);

    &:focus {
        outline: none;
    }

    &:active {
        transform: scale(.95);
    }

    &.reverse {
        flex-direction: row-reverse;
    }

    img {
        width: 17px;
    }
}

#Buttons {
    display: flex;
    gap: 15px;

    &.start {
        justify-content: flex-start;
    }

    &.center {
        justify-content: center;
    }

    &.end {
        justify-content: flex-end;
    }
}