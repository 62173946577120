// --btn_bg
// --btn_border
// --btn_border_radius
// --btn_height
// --btn_width

&._1 {
  --btn_bg: transparent;
  --btn_border_radius: 10px;
  --btn_height: 45px;
  --btn_width: 150px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--secondary-color);
  // display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  opacity: 1;
  padding: 10px 20px;

  &.__1 {
    --btn_bg: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: #fff;

    &.___1 {
      position: relative;
      top: -10px;
      left: -9px;
    }
  }

  &.__2 {
    width: 50px;
  }

}

&._2 {
  min-height: 42px;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  background-color: transparent;
  padding: 3px 17px;
  font-size: 14px;
}

&._3 {
  --btn_height: 30px;
  --btn_border_radius: 5px;
  background-color: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-size: 12px;

  img {
    width: 12px;
  }

}

&._4 {
  background-color: #fff;
  border-radius: 5px;
}

&._5 {
  width: 100%;
  // height: 15px;
  line-height: 100%;
  padding: 0 0 2px;
  border-radius: 10px;
  font-weight: bold;
}