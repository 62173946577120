#Notifications {

    p {
        margin: 0;
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid gainsboro;

        p.count {
            font-size: 10px;
            border-radius: 6px;
            padding: 5px 10px;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        width: 300px;
        height: 300px;
        overflow: auto;
        padding-right: 20px;

        li {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            padding-left: 10px;

            span {
                cursor: pointer;

                p.title {
                    font-size: 14px;
                }

                p.message {
                    font-size: 12px;
                }
            }

            button {
                background: none;
                border: none;
                height: fit-content;
                font-size: 14px;
            }
        }

        #Button {
            border: none;
            color: var(--primary-color);
            margin-left: 20px;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        }

        &::-webkit-scrollbar-thumb {
            outline: none;
            border-radius: 100px;
        }
    }

    .footer {
        border-top: 1px solid gainsboro;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            border: none;
            background-color: inherit;
            font-size: 14px;
        }
    }
}

.ToolTip_anchor {

    .notification_icon {
        width: 40px;
        border: 1px solid #D9D9D9;
        border-radius: 5px;
        padding: 8px;

    }

    .new_notifications {
        display: inline-block;
        background-color: red;
        width: 7px;
        height: 7px;
        border-radius: 50px;
        position: absolute;
        right: 5px;
        top: 5px;
    }
}