& {
    p.key {
        text-shadow: 0 0 1px;
    }
}

&._1 {
    a.hyperlink p {
        color: #2363F9;
    }

    p.value {
        p {
            &.key {
                color: red;
                opacity: 64% !important;
                font-size: 14px !important;
            }
        }

        img.icon {
            height: 34px;
        }
    }
}

&._2 {
    display: flex;
    flex-direction: row;

    p.key {
        font-weight: bold;
    }

    p.value {
        direction: ltr;
    }
}