& {
    --sbx-additionalinformationrequired: #727f0b;
    --sbx-approved: #14A645;
    --sbx-assigned: #6D99FF;
    --sbx-assignedtopool: #463D80;
    --sbx-callbackdetailsupdated: #6D99FF;
    --sbx-callbackrequest: #0C6709;
    --sbx-cancelled: #ff607c;
    --sbx-changerequested: #933856;
    --sbx-closed: #0C6709;
    --sbx-completed: #6F9A1A;
    --sbx-confirmed: #1A4ACE;
    --sbx-creator: #1a7e9a;
    --sbx-draft: #7548AA;
    --sbx-escalated: #127bac;
    --sbx-escalation: #84761D;
    --sbx-escalationapprovalsubmission: #0F645E;
    --sbx-forwarded: #DE7012;
    --sbx-mobileapprequest: #DE7012;
    --sbx-needinfo: #2F209B;
    --sbx-notresponded: #727f0b;
    --sbx-processing: #FFBB00;
    --sbx-rejected: #ab1111;
    --sbx-rejectedescalationrequest: #D93F3F;
    --sbx-reopened: #1F4638;
    --sbx-requestcreated: #d952d7;
    --sbx-resolved: #31AFDE;
    --sbx-sharedtopool: #1a7e9a;
    --sbx-sharedtouser: #6F9A1A;
    --sbx-tobereviewed: #463D80;
    --sbx-todo: #0F645E;
    --sbx-voicemailrequest: #7548AA;
    --sbx-waitingforapproval: #84761D;
    --sbx-websiterequest: #78858b;
}

&._1 {

    font-size: 13px;

    .box {
        background-color: inherit;
        color: var(--sbx-color);
        gap: 10px;
        justify-content: right;
        font-size: 13px;
        min-width: 60px;
        text-wrap: nowrap;

        &::before {
            content: '';
            width: 10px;
            background-color: var(--sbx-color);
            border-radius: 50%;
            aspect-ratio: 1 / 1;
            flex-shrink: 0;
        }
    }
}

&._2 {
    --sbx-callback: #EC574D;
    --sbx--callback: #F6E5E3;

    --sbx-voicemail: #F59537;
    --sbx--voicemail: #FEF3E6;

    --sbx-website: #2363F9;
    --sbx--website: #2363F936;

    --sbx-app: #599637;
    --sbx--app: #EAF4E5;

    --sbx-manual: #626262;
    --sbx--manual: #eaeaea;

    --sbx-email: #05a38e;
    --sbx--email: #c8f3f2;

    .box {
        color: var(--sbx-color);
        background-color: var(--sbx--color);
        background-blend-mode: lighten;
        border-radius: 4px;
        font-size: 12px;
        min-width: 80px;
    }
}

&._3 {

    .box {
        background-color: var(--sbx-color);
        color: white;
        padding: 0 20px;

        &.processing {
            color: black;
        }
    }
}