.css_oth {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-size: 20px;

    #_network_lost,
    #_page_not_found {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
            border: none;
            background-color: gainsboro;
            border-radius: 100px;
            font-size: 15px;
            padding: 10px 20px;
        }
    }

    #_Loader {
        --Loader_color: #000;
        --Loader_thickness: 3px;
        --Loader_size: 30px;

        width: var(--Loader_size);
        height: var(--Loader_size);
        border-radius: 50%;
        display: inline-block;
        border-top: var(--Loader_thickness) solid var(--Loader_color);
        border-right: var(--Loader_thickness) solid transparent;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}