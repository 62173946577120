#InstaGridItem {
    flex: 1 1 100px;
    height: 100%;
    position: relative;

    .icon {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 25px;
    }
    .tag{
        position: absolute;
        left: 20px;
        top: 20px;
        border-radius: 8px;
        background: #e0e0e0;
        box-shadow: 20px 20px 60px #9b9b9b, -20px -20px 60px #ffffff;
    }

    .feed {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
        cursor: pointer;
    }
}