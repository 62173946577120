span#Input {
    --inp_bg: #9b9b9b;
    --inp_border_radius: 5px;

    display: inline-flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;

    $icon_width: 18px;
    $icon_margin: 12px;
    $input_x_padding: 15px;

    label {
        em {
            color: red;
            margin: 0 5px;
            font-style: normal;
        }

        .label_icon {
            margin-right: 10px;
            cursor: pointer;
        }
    }

    span.icon_wrap {
        position: relative;
        display: inline-flex;
        align-items: center;
        width: 100%;

        input {
            box-sizing: border-box;
            border: none;
            background-color: var(--inp_bg);
            border-radius: var(--inp_border_radius);
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: $input_x_padding;
            padding-left: calc($icon_width + ($icon_margin * 2));
            width: 100%;
            direction: ltr;
            text-align: right;

            &:focus {
                outline: none;
            }

            &[type='number'] {

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }

                -moz-appearance: textfield;
            }
        }

        .input_icon {
            position: absolute;
            width: $icon_width;
            left: $icon_margin;

            &~.input_icon {
                left: calc($icon_width * 2);
            }
        }

        span.close_btn {
            position: absolute;
            left: 10px;
            cursor: pointer;
        }
    }

    small.error {
        color: red;
    }

    &.reverse {
        span {
            input {
                padding-right: calc($icon_width + ($icon_margin * 2));
                padding-left: $input_x_padding;
            }

            img {
                right: $icon_margin;
            }
        }
    }
}