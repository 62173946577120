&._1 {
  label {
    color: var(--secondary-color);
    padding-bottom: 7px;
    font-size: 14px;
    font-weight: 400;
  }

  span.icon_wrap {
    input {
      background-color: transparent;
      border: 1px solid #cccccc;
      font-size: 14px;
      padding: 10px 20px;
      border-radius: 8px;
    }
  }
}

&._2 {
  label {
    color: #3e3e3e;
    padding-bottom: 7px;
    font-size: 14px;
    font-weight: 400;
  }

  &.reverse span img {
    right: 14px;
  }

  span.icon_wrap {
    input {
      background-color: transparent;
      border: 1px solid #cccccc;
      // box-shadow: #3e3e3e21 0px 1px 4px;
      font-size: 14px;
      padding: 10px 20px;
      border-radius: 8px;
      padding-right: 40px;
    }

    ::placeholder {
      color: #161616;
      opacity: 1;
    }

    ::-ms-input-placeholder {
      color: #161616;
    }
  }
}

&._3 {
  input {
    border: none;
    background-color: transparent;
  }
}