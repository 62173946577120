// --slc_border_radius:;
// --slc_height:;

// --slc_bg_color:;
// --slc_f_bg_color:;

// --slc_text_color:;
// --slc_f_text_color:;
// --slc_text_font_size:;

// --slc_border: 1px solid;
// --slc_f_border: 1px solid;

// --slc_op_height:;
// --slc_op_padding:;
// --slc_op_font_size:;

// --slc_op_bg_color:;
// --slc_op_text_color:;

// --slc_op_pl_text_color:;

// --slc_op_h_bg_color:;
// --slc_op_h_text_color:;

// --slc_op_selected_bg_color:;
// --slc_op_selected_text_color:;

&._1 {

    p.title {
        margin-bottom: 0;
        padding-bottom: 7px;
        font-size: 14px;
        font-weight: 400;
    }

    --slc_border_radius: 7px;
    --slc_height: 45px;

    --slc_bg_color: #fff;
    --slc_f_bg_color: #fff;

    --slc_text_color: inherit;
    --slc_f_text_color: inherit;
    --slc_text_font_size:14px;

    --slc_border: 1px solid #d9d9d9;
    --slc_f_border: 1px solid #C71513;

    --slc_op_height: 40px;
    --slc_op_padding: 10px;
    --slc_op_font_size:14px;

    --slc_op_h_bg_color: #FDEBED;
    --slc_op_h_text_color: #C71513;

    --slc_op_selected_bg_color: #FDEBED;
    --slc_op_selected_text_color: #C71513;

    --slc_op_checkbox_accent:#C71513;

    &.__1 {
        --slc_bg_color: transparent;
        --slc_f_bg_color: transparent;

        --slc_height: 43px;
        --slc_border: 1px solid #cccccc;
        --slc_f_border: initial;
    }

}

&._2 {
    p.title {
        margin-bottom: 0;
        padding-bottom: 7px;
        font-size: 14px;
        font-weight: 400;
    }

    --slc_border_radius: 8px;
    --slc_height: 43px;

    --slc_bg_color: transparent;
    --slc_f_bg_color: transparent;

    --slc_text_color: inherit;
    --slc_f_text_color: inherit;
    --slc_text_font_size: 14px;

    --slc_op_height: 25px;
    --slc_op_padding: 2px 5px;
    --slc_op_font_size: 14px;

    --slc_op_h_bg_color: #FDEBED;
    --slc_op_h_text_color: #C71513;

    --slc_op_selected_bg_color: #C71513;
    --slc_op_selected_text_color: #fff;
}