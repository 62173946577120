div#Table {

    .table_handler {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;
        margin-bottom: 30px;

        &:empty {
            display: none;
        }
    }

    .table_content {
        overflow: auto;

        &::-webkit-scrollbar {
            // display: none;
            height: 5px;
            width: 10px;
            background-color: rgb(248, 248, 248);
            cursor: pointer;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--primary-color);
            border-radius: 10px;
            cursor: pointer;
        }

        table {
            border-collapse: collapse;
            width: 100%;

            thead {
                th {
                    padding: 12px 10px;
                    white-space: nowrap;
                    border: 1px solid;
                    position: relative;
                    top: 0px;

                    &.sort {
                        padding-left: 20px;
                    }
                }
            }

            tbody {
                tr {
                    $hover-color: #f8f8f8;

                    &:hover {
                        background-color: $hover-color;

                        &+tr.other_fields {
                            background-color: $hover-color;
                        }

                    }

                    &:has(+ tr.other_fields:hover) {
                        background-color: $hover-color;
                    }

                    td {
                        height: 50px;
                        padding: 10px;
                        border: 1px solid;
                        position: relative;

                        img.toggle.resp {
                            margin-left: 10px;
                            position: relative;
                            // right: 15px;
                            // top: 50%;
                            top: 14%;
                            cursor: pointer;
                            transform: translateY(-50%);

                            &.on {
                                rotate: 180deg;
                                top: -12%;
                            }
                        }

                        img.toggle.additional {
                            margin-left: 10px;
                            cursor: pointer;
                        }
                    }

                    &.other_fields {
                        td {
                            padding-right: 60px;

                            &>div {
                                display: inline-flex;
                                gap: 40px;
                                flex-wrap: wrap;
                                row-gap: 20px;

                                &>span {
                                    &>label {
                                        white-space: nowrap;
                                        position: relative;
                                        margin-bottom: 7px;
                                        font-weight: bold;

                                        .sorter {
                                            top: 1px !important;
                                            right: -15px !important;
                                        }
                                    }

                                    &>span {
                                        min-height: 30px;
                                        display: flex;
                                        align-items: center;
                                    }
                                }
                            }
                        }

                        &.toggle_off {
                            display: none;
                        }
                    }

                    &.additonal_details {

                        td {
                            padding: 30px;
                            padding-right: 40px;
                        }

                        &.toggle_off {
                            display: none;
                        }
                    }
                }
            }

            &~p.no__data {
                text-align: center;
                margin: 30px 0 50px;
            }

            span.sorter {
                position: relative;
                top: -10px;
                display: inline-flex;
                flex-direction: column;

                &:hover {
                    cursor: pointer;
                }

                $inActiveOpacity:.5;

                &:after,
                &:before {
                    font-size: 11px;
                    line-height: 11px;
                    color: #0A0F31;
                    opacity: $inActiveOpacity;
                }

                &:after {
                    content: "▼";
                }

                &:before {
                    content: "▲";
                }

                &.sort__asc {
                    &:before {
                        opacity: 1;
                    }

                    &:after {
                        opacity: $inActiveOpacity;
                    }
                }

                &.sort__dsc {
                    &:before {
                        opacity: $inActiveOpacity;
                    }

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}