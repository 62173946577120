& {
    --navbar_width: 250px;
    --header_height: 75px;

    header {
        margin-right: calc(var(--navbar_width) * -1);
        width: 100vw;
        border-bottom: 1px solid #e0e3e8;
        background: #ffffff 0% 0% no-repeat padding-box;
    }

    aside {
        // box-shadow: 0px 10px 20px rgb(0 0 0 / 4%);
        background: #ffff;
    }
}