.Dashboard {

    #Page_Body {

        display: grid;
        gap: 20px;
        grid-template-rows: repeat(8, auto);

        &>* {
            border-radius: 10px;
            background-color: #fff;
            border-bottom: 1px solid #e0e3e8;
        }

        #Total {
            text-align: center;
            padding: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px;
            background-color: #fff;
            border-bottom: 1px solid #e0e3e8;

            &.clickable {
                cursor: pointer;
            }

            .title {
                font-size: 20px;
                text-align: left;
                line-height: 1.3;
            }

            .count {
                text-align: left;
                font-size: 35px;
                font-weight: bold;
                color: #C71513;
            }
        }

        .counts_img {
            width: 80px;
            height: 80px;
            border-radius: 10px;
            box-shadow: 0 0 2px #e5e9ec;
            border-bottom: 1px solid #eeeeee;
            padding: 20px;
            border-radius: 5px;
        }

        #Counts {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
            background-color: transparent;
            box-shadow: none;
            border-bottom: none;
        }

        #CallDetails {
            background-color: #fff;
            border-bottom: 1px solid #e0e3e8;
            border-radius: 10px;
            display: flex;
            align-items: center;
            padding: 20px;
            font-size: 14px;
            gap: 20px;

            thead {
                th {
                    font-weight: normal;
                }
            }

            table {
                border-radius: 10px;
                overflow: hidden;
            }

            tbody {
                tr {
                    &:last-child {
                        td {
                            padding-top: 2px;
                        }
                    }
                }
            }

            th,
            td {
                font-weight: bold;
                color: #C71513;
                padding-right: 15px;
                font-size: 18px;
                line-height: 1;

                &.text {
                    font-size: 12px;
                    color: black;
                    text-align: right;
                    font-weight: normal;
                }

                &.co {
                    width: 100px;
                    line-height: 1.2;
                    padding-bottom: 10px;
                }

                &:first-child {
                    width: 60px;
                    padding-right: 0;
                }
            }
        }

        h3 {
            background-color: transparent;
            box-shadow: none;
            border-bottom: none;
            font-size: 18px;
            margin: 0;
            position: relative;
            top: 6px;
        }

        #Filter {
            display: flex;
            gap: 20px;
            padding: 20px;
        }

        .circles {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;
            background-color: transparent;
            box-shadow: none;
            border-bottom: none;

            &>* {
                background-color: #fff;
                border-bottom: 1px solid #e0e3e8;
                padding: 20px;
                border-radius: 10px;
            }
        }

        .legacycircles {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            background-color: transparent;
            box-shadow: none;
            border-bottom: none;

            &>* {
                background-color: #fff;
                border-bottom: 1px solid #e0e3e8;
                padding: 20px;
                border-radius: 10px;
                height: 423px;
            }

            #ChartPolar,
            #ChartDoughnut {
                position: relative;

                p {
                    position: absolute;
                    top: 20px;
                    left: 30px;
                }
            }
        }

        .legacy_table {
            background-color: transparent;
            box-shadow: none;
            border-bottom: none;
        }

        .bars {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            background-color: transparent;
            box-shadow: none;
            border: none;

            &>* {
                padding: 20px;
                background-color: #fff;
                border-radius: 10px;
                border-bottom: 1px solid #e0e3e8;
            }

            p {
                text-align: center;
            }
        }

        .InstagramFeeds {
            flex-direction: column;
            position: relative;
            background-color: #fff;
            padding: 0 20px 20px;

            .content {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: 350px;
                gap: 10px;
                align-items: center;
                width: 100%;
            }

            .head {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px;
                padding-bottom: 10px;

                .view_all {
                    color: var(--primary-color);
                    font-size: 12px;
                    cursor: pointer;
                }
            }
        }

        #Agents {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            background-color: transparent;
            box-shadow: none;
            border: none;

            .agent_item {
                min-height: 200px;
                background-color: #fff;
                border-bottom: 1px solid #e0e3e8;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px 30px;

                .head {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    border-bottom: 1px solid #eee;
                    padding-bottom: 12px;
                    margin-bottom: 6px;

                    p {
                        text-align: left;
                        line-height: 100%;

                        &.name {
                            margin-bottom: 5px;
                        }

                        &.status {
                            color: green;
                            font-size: 12px;

                            &.away {
                                color: orange;
                            }

                            &.unavailable {
                                color: red;
                            }
                        }
                    }

                    .agent_profile {
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }

                p.detail {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .key {
                        color: #aaa;
                        font-size: 14px;
                    }

                    .value {
                        width: 60px;
                    }

                    .colon {
                        width: 15px;
                        margin-right: auto;
                    }
                }
            }
        }
    }

    #Calls {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 10px;
        background-color: transparent;
        box-shadow: none;
        border: none;

        .call_item {
            display: inline-block;
            background-color: #fff;
            border-bottom: 1px solid #e0e3e8;
            border-radius: 10px;
            padding: 10px 25px;
            position: relative;

            .icon {
                position: absolute;
                left: 15px;
                top: 43%;
            }

            .count {
                font-size: 30px;
                // font-weight: bold;
            }
        }
    }
}