#ModalContent {

    $header_height: 50px;

    p.header {
        background-color: #F4ECE8;
        padding: 10px;
        padding-right: 40px;
        font-size: 18px;
        border-radius: 10px 10px 0 0;
        margin: 0;
        height: $header_height;
    }

    .content {
        height: calc(100% - $header_height);

        h3 {
            font-size: 20px;
            margin: 0;

            #App[dir="rtl"] & {
                text-align: right;
            }
        }

        object {
            width: 100%;
            height: 400px;
            border-bottom: 3px solid;
            border-top: 3px solid;
            overflow: hidden;
        }
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 15px;
        margin-top: 20px;

        &:empty {
            display: none;
        }
    }

    img.close_btn {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }
}