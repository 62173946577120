#Login {
    height: 100vh;
    position: relative;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }

    .logo {
        margin: 140px auto 0;
        display: block;
        // border-bottom: 1px solid red;
        padding: 0 50px 30px;
        height: 150px;
    }

    #Logs {
        height: 100%;
        width: 100%;
        background-image: linear-gradient(0, #fff 30%, 70%, transparent);
        z-index: 1;
        position: absolute;
        padding: 0 30%;
        // min-height: 1000px;

        .Username,
        .Password {
            input {
                background-color: transparent;
                border: 1px solid #0E0E0E;
                border-radius: 5px;
                height: 50px;

                // &:-webkit-autofill:hover,
                // &:-webkit-autofill:focus,
                // &:-webkit-autofill:active,
                &:-webkit-autofill {
                    -webkit-background-clip: text;
                    // -webkit-text-fill-color: #ffffff;
                    // transition: background-color 5000s ease-in-out 0s;
                    // box-shadow: inset 0 0 20px 20px transparent;
                }
            }

            .input_icon {
                left: 20px;
            }
        }

        .Submit {
            width: 100%;
            height: 50px;
            border: 1px solid #0E0E0E;
            background-color: transparent;

            .spinner-border {
                color: red;
            }
        }

        .forgot_pwd {
            color: rgb(122, 122, 122);
            text-align: left;
            direction: ltr;
            margin-top: 15px;
            cursor: pointer;
            width: fit-content;
        }
    }
}