span#DatePick {

  --dtp_f_border: 1px solid #000;
  --dtb_border_radius: 5px;
  --dtb_height: 40px;

  display: inline-flex;
  flex-direction: column;
  width: 100%;

  label {
    em {
      color: red;
      margin: 0 5px;
      font-style: normal;
    }
  }

  .react-datepicker-popper {
    min-width: 242px;
  }

  &.time-select {
    .react-datepicker-popper {
      min-width: 330px;
    }
  }

  span.datepic_wrapper {
    display: inline-flex;
    align-items: center;
    position: relative;

    img {
      position: absolute;
      left: 10px;
    }

    img.calendar {
      pointer-events: none;
    }

    span.close_btn {
      position: absolute;
      left: 10px;
      cursor: pointer;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    input {
      width: 100%;
      height: var(--dtb_height);
      box-sizing: border-box;
      padding: 0;
      padding-right: 15px;
      padding-left: 35px;
      font-size: 14px;
      direction: ltr;
      text-align: right;
      border-radius: var(--dtb_border_radius);
      border: var(--dtp_border);

      &:focus {
        border: var(--dtp_f_border);
      }

      &:focus-visible {
        outline: none;
      }
    }

    .react-datepicker__triangle::after,
    .react-datepicker__triangle::before {
      left: -30px;
    }
  }

  small.error {
    color: red;
  }
}