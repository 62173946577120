#Checkbox {
    --toggle_switch_color_off: black;
    --toggle_switch_color_on: gray;
    --toggle_switch_width: 50px;
    --toggle_switch_height: 25px;
    --toggle_switch_bg_false: var(--toggle_switch_color_off);
    --toggle_switch_bg_true: var(--toggle_switch_color_on);
    --toggle_switch_label_false: var(--toggle_switch_color_off);
    --toggle_switch_label_true: var(--toggle_switch_color_on);
    --toggle_button_margin: 5px;

    --cbx_bg: #9b9b9b;

    display: inline-flex;
    flex-direction: column;
    background-color: var(--cbx_bg);

    &>span {
        display: inline-flex;
        gap: 10px;
        accent-color: #000;
        align-items: center;

        &.reverse {
            flex-direction: row-reverse;
            justify-content: flex-end;
        }

        &>label {
            em {
                color: red;
                margin: 0 5px;
                font-style: normal;
            }
        }
    }

    small {
        color: red;
        line-height: .875em;
    }

    #ToggleSwitch {
        background-color: var(--toggle_switch_bg_false);
        color: var(--toggle_switch_label_false);
        width: var(--toggle_switch_width);
        height: var(--toggle_switch_height);
        border-radius: 1000px;
        display: inline-flex;
        justify-content: flex-end;
        cursor: pointer;
        position: relative;

        &>label {
            margin-left: 10px;
            user-select: none;
            position: relative;
            top: 2px;
            cursor: pointer;
        }

        &>span {
            --toggle_button_size: calc(100% - (var(--toggle_button_margin) * 2));
            background-color: rgb(255, 255, 255);
            display: inline-block;
            margin: var(--toggle_button_margin);
            height: var(--toggle_button_size);
            aspect-ratio: 1 / 1;
            border-radius: 50%;
            transition: right .5s, left .5s;
            position: absolute;
            right: 0;
            left: auto;
            transition: all .5s;
        }

        &[data-checked="true"] {
            background-color: var(--toggle_switch_bg_true);
            justify-content: flex-start;
            color: var(--toggle_switch_label_true);

            &>label {
                margin-right: 10px;
                margin-left: 0;
                position: relative;
            }

            &>span {
                transform: translateX(calc((var(--toggle_switch_width) - var(--toggle_switch_height)) * -1));
            }
        }

        &.disabled {
            color: #9b9b9b;
            background-color: rgb(216, 216, 216);
        }
    }
}