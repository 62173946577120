#Header {
  height: 100%;
  display: flex;

  div.logo {

    width: var(--navbar_width);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 130px;
    }
  }

  div.actions {
    display: flex;
    justify-content: space-between;
    width: calc(100% - var(--navbar_width));
    padding: 10px 30px;

    .switches {
      display: flex;
      gap: 10px;
      margin: auto 0;

      #Button {
        height: 45px;
      }

    }

    .user_menu {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-right: auto;

      .user_details {

        .name {
          font-size: 14px;
        }

        .role {
          font-size: 11px;
          color: var(--primary-color);
        }

        .department {
          font-size: 11px;
          color: var(--primary-color);
        }

      }

      .user_image {
        img {
          width: 40px;
          height: 40px;
          border-radius: 6px;
          object-fit: cover;
          padding: 7px;
        }
      }

      .user_info {
        padding: 20px;
        min-width: 250px;

        p {
          font-size: 13px;

          &.detail {
            display: flex;
            flex-direction: row-reverse;
            justify-content: start;
            gap: 10px;

            &.dept_name {
              width: max-content;
              margin-bottom: 10px;
            }
          }
        }

        em {
          color: #aeb0b3;
          font-style: normal;
        }

        .logout {
          padding: 10px;
          margin-top: 10px;
          color: #fff;
          background-color: var(--primary-color);
          border-radius: 5px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}