span#Select {

    --slc_border_radius: 5px;
    --slc_height: 40px;

    --slc_bg_color: #7c7c7c;
    --slc_f_bg_color: #a7a7a7;

    --slc_text_color: #cfcfcf;
    --slc_f_text_color: #ffffff;
    --slc_text_font_size: 16px;

    --slc_border: 1px solid #000000;
    --slc_f_border: 1px solid #474747;

    --slc_op_height: 50px;
    --slc_op_padding: 10px 8px;
    --slc_op_font_size: 16px;

    --slc_op_bg_color: #ffffff;
    --slc_op_text_color: #9b9b9b;

    --slc_op_pl_text_color: #757575;

    --slc_op_h_bg_color: #808080;
    --slc_op_h_text_color: #c9c9c9;

    --slc_op_selected_bg_color: #bebebe;
    --slc_op_selected_text_color: #000000;

    --slc_op_checkbox_accent: #000;

    display: inline-flex;
    flex-direction: column;
    width: 100%;

    span.select__indicator-separator,
    span.css-1u9des2-indicatorSeparator,
    span.css-894a34-indicatorSeparator {
        display: none;
    }

    p.title {
        em {
            color: red;
            margin: 0 5px;
            font-style: normal;
        }

        img.toggle_arrow {
            margin-right: 10px;
            position: relative;
            top: -3px;
            cursor: pointer;
            user-select: none;

            &.collapsed {
                rotate: 180deg;
            }
        }
    }

    .select_react_select {
        width: 100%;

        // select on focus
        .css-t3ipsp-control {
            background-color: var(--slc_f_bg_color, var(--slc_bg_color));
            border: var(--slc_f_border, var(--slc_border));
            box-shadow: none;
            border-radius: var(--slc_border_radius);
            height: var(--slc_height);

            .css-1dimb5e-singleValue,
            .css-1jqq78o-placeholder {
                color: var(--slc_f_text_color, var(--slc_text_color));
                font-size: var(--slc_text_font_size);
            }
        }

        // select on blur
        .css-13cymwt-control {
            background-color: var(--slc_bg_color);
            border: var(--slc_border);
            transition: none;
            border-radius: var(--slc_border_radius);
            height: var(--slc_height);

            .css-1dimb5e-singleValue,
            .css-1jqq78o-placeholder {
                color: var(--slc_text_color);
                font-size: var(--slc_text_font_size);
            }

            .css-1jqq78o-placeholder {
                color: var(--slc_op_pl_text_color);
            }
        }

        // select on disabled
        .css-16xfy0z-control {
            height: var(--slc_height);
            border-radius: var(--slc_border_radius);

            .css-1dimb5e-singleValue,
            .css-1jqq78o-placeholder {
                color: var(--slc_text_color);
                font-size: var(--slc_text_font_size);
            }
        }

        // inactive option
        // hover option
        // selected option
        .css-10wo9uf-option,
        .css-d7l1ni-option,
        .css-tr4s17-option {
            height: var(--slc_op_height);
            padding: var(--slc_op_padding);
            background-color: var(--slc_op_bg_color);
            color: var(--slc_op_text_color);
            font-size: var(--slc_op_font_size);
        }

        // hover option
        .css-d7l1ni-option {
            background-color: var(--slc_op_h_bg_color);
            color: var(--slc_op_h_text_color);
        }

        // selected option
        .css-tr4s17-option {
            background-color: var(--slc_op_selected_bg_color, var(--slc_op_bg_color));
            color: var(--slc_op_selected_text_color, var(--slc_op_text_color));
        }

        .css-1n6sfyn-MenuList::-webkit-scrollbar {
            width: 8px;
            border-radius: 100px;
        }

        .css-1n6sfyn-MenuList::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgb(255, 255, 255);
        }

        .css-1n6sfyn-MenuList::-webkit-scrollbar-thumb {
            background-color: #3333;
            outline: 1px solid #fff;
            border-radius: 100px;
        }

    }

    .rmsc * {
        transition: none;
    }

    .rmsc.select_multi_select {
        width: 100%;

        .dropdown-heading-value {
            font-size: var(--slc_text_font_size);
        }

        .clear-selected-button {
            display: none;
        }

        .dropdown-heading-dropdown-arrow {
            display: none;
        }
    }

    .select_multi_select {
        box-shadow: none;
        border: 0;

        .dropdown-container {
            border: var(--slc_border);
            border-radius: var(--slc_border_radius);
            height: var(--slc_height);

            &:focus-within {
                box-shadow: none;
                border: var(--slc_f_border, var(--slc_border));
            }

            &:focus {
                box-shadow: none;
            }

            .dropdown-heading {
                height: var(--slc_height);

                .dropdown-heading-value {
                    width: 100px;
                    overflow: hidden;
                }
            }
        }

        .dropdown-content {
            .search {
                input {
                    background-color: #fff;
                }
            }

            .options {
                padding: 0;

                li {
                    padding: 0;


                    label {
                        height: var(--slc_op_height);
                        padding: var(--slc_op_padding);
                        font-size: var(--slc_op_font_size);
                        background-color: var(--slc_op_bg_color);
                        color: var(--slc_op_text_color);

                        .item-renderer {
                            gap: 10px;
                        }

                        input {
                            accent-color: var(--slc_op_checkbox_accent, --slc_op_selected_bg_color);
                        }

                        &:hover {
                            background-color: var(--slc_op_h_bg_color);
                            color: var(--slc_op_h_text_color);
                        }

                        &.selected {
                            background-color: var(--slc_op_selected_bg_color);
                            color: var(--slc_op_selected_text_color);
                        }
                    }
                }
            }
        }

        ul.options {
            &::-webkit-scrollbar {
                width: 8px;
                border-radius: 100px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgb(255, 255, 255);
            }

            &::-webkit-scrollbar-thumb {
                background-color: #3333;
                outline: 1px solid #fff;
                border-radius: 100px;
            }
        }
    }


    &.close_button_appear {
        .select_span {
            position: relative;
            display: inline-flex;
            align-items: center;

            .select_react_select {

                .css-13cymwt-control,
                .css-t3ipsp-control {
                    padding-left: 30px;
                }

                .css-1xc3v61-indicatorContainer,
                .css-15lsz6c-indicatorContainer {
                    display: none;
                }

                .css-b62m3t-container,
                .css-3iigni-container {
                    flex-grow: 1;
                }
            }

            .close_btn {
                position: absolute;
                left: 10px;
                cursor: pointer;
            }
        }
    }

    small.error {
        color: red;
    }
}