// DEFAULT{
.pad-20 {
  padding: 20px;
}

hr {
  border: 1px solid rgba(0, 0, 0, 0.141);
}

div.upload_photo {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  margin-bottom: 50px;

  span.upload__img {
    width: 100px;
    height: 100px;
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img.photo {
    height: 24px;

    &.uploaded {
      border-radius: 50%;
      height: 100px;
      width: 100px;
      padding: 0;
      object-fit: cover;
    }
  }

  &>div {
    display: flex;
    flex-direction: column;

    p {
      font-size: 12px;
      position: absolute;
      top: 75px;
      color: #00162c;
      opacity: 70%;
      font-weight: 500;
    }
  }
}

#App {


  //   AUDIO SECTION
  .rhap_container svg {
    padding: 6px;
  }

  .rhap_time {
    font-size: 14px;
    margin: 0 10px;
  }

  .rhap_progress-indicator {
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    width: 13px;
    height: 13px;
    margin-left: -10px;
    top: -4px;
    background: #868686;
    border-radius: 50px;
    box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
  }

  .rhap_container {
    border-radius: 100px;
    width: 100%;
    padding: 0px 15px;
    // margin-top: 20px;
    border: 1px solid #3e3e3e;

    .rhap_horizontal {
      flex-direction: row-reverse;
    }

    .rhap_controls-section {
      // width: 20px;
      flex: none;
      margin: 0;
    }

    .rhap_main-controls path {
      color: #c71513;
    }
  }

  .rhap_play-status--paused .rhap_progress-bar {
    background-color: #c71513;
  }

  // .rhap_progress-bar {}

  // .rhap_progress-section {
  //   .rhap_current-time {}

  //   .rhap_progress-container {}

  //   .rhap_time {}
  // }



}











.statictics_cards {
  display: flex;
  gap: 20px;
  margin-bottom: 24px;

  p {
    line-height: 100%;
  }

  .statictics_card {
    background-color: #fff;
    border-radius: 10px;
    flex: 1 0;
    padding: 20px;

    .header {
      display: inline-flex;
      gap: 10px;
      align-items: center;

      .icon {}

      .title {}
    }

    .value {
      text-align: center;
      font-size: 35px;
      margin-bottom: 10px;
    }

    .footer {
      border-top: 1px solid #d9d9d9;
      display: inline-flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      padding-top: 15px;

      hr {
        height: 50px;
      }
    }
  }
}

.charts {

  .chart1,
  .chart2 {
    background-color: #fff;
    margin-bottom: 24px;
    padding-bottom: 20px;

    .content {
      display: flex;
      justify-content: center;
      gap: 30px;
    }

    .chart {
      height: 300px;
      width: 300px;
    }

  }
}



.upcomingFeeds {
  .files {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;

    img {
      width: 250px;
      border-radius: 10px;
    }
  }
}

// ANNONYMOUS
#Button.anonymous {
  text-align: left;
  background: rgb(143, 0, 12);
  background: linear-gradient(90deg, rgb(143, 0, 12) 0%, rgb(213, 1, 23) 100%);
  margin-right: auto;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

h4.subtitle {
  font-size: 16px;
  color: #c71612;
}

.instagramHistory {
  display: flex;
  width: 100%;
  align-items: center;
  background: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;

  .icon {
    padding-left: 10px;
  }

  .name {
    padding-left: 20px;
    width: 100%;

    h2 {
      font-size: 14px;
      margin-bottom: 0;
      color: #c71612;
    }

    p {
      margin-bottom: 0;
      font-size: 14px;
    }
  }
}

table.changes {
  margin-bottom: 10px;

  th {
    font-weight: 600;
    font-size: 14px;
    color: grey;
  }

  tr {
    border-bottom: 1px solid #eee;

    td {
      font-size: 12px;
      padding: 10px 20px;
      text-transform: capitalize;

      &.field {
        min-width: 150px;
      }

      &.old {
        color: rgb(227 46 15);

        &.contact {
          direction: ltr;
          text-align: right;
        }
      }

      &.new {
        color: rgb(16 109 13);
      }
    }

    &.customer_alternate_email,
    &.customer_email,
    &.incident_date {
      td {
        text-transform: none;
      }
    }

    &.customer_phone_number,
    &.customer_alternate_contact,
    &.customer_contact_number {

      .old,
      .new {
        direction: ltr;
      }
    }
  }
}

.Event_detail {
  div#Table .table_handler {
    align-items: flex-end;
  }

  #Input {
    order: 3;
  }
}

.customer_history_table {

  .table_content tbody tr {
    td:first-of-type {
      padding-right: 10px !important;
    }

    &.other_fields {
      td:first-of-type {
        padding-right: 30px !important;
      }
    }
  }

  .table_handler {
    #Select {
      order: 2;
      margin-left: 20px;

      &.filter {
        // margin-right: auto;
      }

      &.status {
        margin-right: 10px;
      }
    }

    #Input {
      order: 3;
    }
  }
}

.audio_player_wrap {
  display: flex;
  gap: 20px;
  position: relative;
  width: fit-content;

  .rhap_container.download {
    padding-left: 35px !important;
  }

  .download_icon {
    position: absolute;
    width: 17px;
    left: 18px;
    top: 12px;
    cursor: pointer;
  }
}

#LabelValue.gender {
  p.value {
    text-transform: capitalize;
  }
}

#LabelValue.customer_contact {
  p.value {
    direction: ltr;
    text-align: right;
  }
}

.AddUser {
  hr {
    margin: 30px 0 20px;
  }
}

.history_status .box {
  margin-bottom: 10px;
}

.call_action {
  position: absolute;
  left: 20px;
  top: 12px;

  img {
    height: 20px;
  }

  .aborted,
  .responded,
  .notanswered {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    padding: 7px 10px;
    border-radius: 5px;

    p {
      text-shadow: .3px .3px;
    }
  }

  .aborted {
    background-color: rgb(249 0 0 / 20%);
    color: #f90000;

    img {
      filter: brightness(0) saturate(100%) invert(24%) sepia(84%) saturate(7285%) hue-rotate(356deg) brightness(103%) contrast(118%);
    }
  }

  .responded {
    color: #39a706;
    background-color: rgb(57 167 6 / 20%);
  }

  .notanswered {
    background-color: rgb(255 179 0 / 30%);
    color: #df9e04;

    img {
      filter: brightness(0) saturate(100%) invert(65%) sepia(90%) saturate(1493%) hue-rotate(3deg) brightness(92%) contrast(97%);
    }
  }
}


.notification_item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  .close_icon {
    margin-right: 20px;
  }
}

.cust_his_title {
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  background: #f2f2f2;
  padding: 6px;
  margin-bottom: 6px !important;
  margin-top: 20px !important;
  color: #525151;

  &.m_a {
    margin-top: 10px !important;
  }
}

.alternates {

  .key .label_icon {
    filter: brightness(0) saturate(100%) invert(18%) sepia(92%) saturate(2817%) hue-rotate(350deg) brightness(85%) contrast(104%);
  }

  .value {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .contacts {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      cursor: pointer;
    }
  }
}

.alternateemails {
  .value {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 2px !important;
  }
}

.customer_detail_box {
  direction: ltr;
  display: flex;
  align-items: center;
  text-wrap: nowrap;

  img {
    margin-right: 10px;
  }
}

.volunteer_source {
  text-transform: capitalize;
}

.contact_value {
  .value {
    direction: ltr;
  }
}

.tooltip {
  text-transform: capitalize;
}

.incident_files {
  .file.pre {
    height: 50px !important;
    width: 50px !important;
  }

  .attchmnts_title {
    display: flex;
    gap: 10px;

    .statuses {
      display: flex;
      flex-direction: row-reverse;
      gap: 20px;
    }
  }

  em {
    font-style: normal;
    background-color: #ebebeb;
    padding: 2px 10px;
    border-radius: 3px;
    position: relative;

    &::after {
      content: '/';
      position: absolute;
      right: -11px;
      color: black;
    }

    &:last-of-type {
      &::after {
        content: '';
      }
    }

    &.pending {
      color: rgb(158, 131, 11);
    }

    &.errors {
      color: #c71513;
    }

    &.success {
      color: green;
    }
  }
}

.customer_history_incident_details {
  position: relative;

  #Button {
    margin-top: 20px;
  }
}

.cll_ico {
  .icon2 {
    width: 20px !important;
  }
}

.add_attachments {
  direction: ltr;
  text-align: right;
  font-size: 13px;
  color: #919191;
}

.lr_device {
  text-transform: capitalize;
}


.incident_reports,
.incident_myrequests,
.incident_requests {
  .customer_contact {
    white-space: nowrap;
  }
}

.lr_requests {
  .contact_number {
    white-space: nowrap;
  }
}

.customer_history_table {
  td.phone {
    white-space: nowrap;
  }
}

.evh_item {
  display: flex;
  gap: 10px;

  span:first-child {
    width: 100px;
  }

}

.vr_alter_cntcts {
  display: flex;
  flex-direction: column;
  direction: ltr;
  text-align: right;
}

.noti_clear {
  width: 100px !important;
  height: 30px !important;
  border-radius: 5px !important;
}

.as-vl-tbl .table_content {
  padding-bottom: 86px;
  border-bottom: none !important;

  tbody {
    td {
      position: unset !important;
    }

    tr:last-child {
      border-bottom: 1px solid #e0e3e8;

    }
  }

}

.tlb_status {
  position: relative;
  top: -8px;
}

.evnt-detail-table {
  .table_content {
    padding: 30px 0 90px;
  }
}

.error-files {
  margin-top: 20px;

  .error-file {
    display: flex;
    font-size: 14px;
    color: #c71513;
    gap: 10px;
    align-items: center;
    margin-bottom: 5px !important;

    img {
      height: 18px;
      width: 18px;
      filter: brightness(0) saturate(100%) invert(14%) sepia(62%) saturate(6329%) hue-rotate(356deg) brightness(90%) contrast(91%);
    }
  }
}

.volunteers_table {
  #EntriesCounter {
    order: 1;
  }

  #Input {
    order: 4;
  }

  #Select {
    order: 3;
  }

  #Button {
    order: 2;
    margin-left: 10px;
  }
}

.button_info {
  display: flex;
  gap: 20px;

  img.caution {
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(13%) sepia(86%) saturate(3146%) hue-rotate(358deg) brightness(106%) contrast(147%);
  }
}

.subitem-icon {
  cursor: pointer;
}

// .array_collector_item.active {
//   border-bottom: 2px solid var(--primary-color);
//   position: relative;

//   &:after {
//     content: '〈';
//     direction: ltr;
//     position: absolute;
//     left: -15px;
//     color: var(--primary-color);
//     font-weight: bold;
//   }
// }