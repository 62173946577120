&._1 {
    li {
        .page-link {
            font-size: 14px;
            color: var(--secondary-color);
            border-radius: 1px solid #D9D9D9;
            border-radius: 5px;
            margin: 0 2px;

            &:focus {
                box-shadow: none;
                outline: none;
            }

            &[aria-label="Previous page"],
            &[aria-label="Next page"] {
                background-color: transparent;
                border: none;
                font-weight: 500;
                font-size: 13px;
            }
        }
    }

    li.pageActive a.page-link {
        background-color: var(--primary-color);
        color: white;
    }
}