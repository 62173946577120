// --dtp_f_border:;
// --dtb_border_radius:;

&._1 {
    --dtp_border: 1px solid #cccccc;
    --dtp_f_border: 1px solid #C71513;
    --dtb_border_radius: 7px;
    --dtb_height: 45px;

    label {
        padding-bottom: 7px;
        font-size: 14px;
        font-weight: 400;
    }

    span input {
        background-color: transparent;
        font-size: 14px;
        padding: 10px 20px;
        border-radius: 8px;
    }
}