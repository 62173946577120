div#Card {
    --crd_head_bg: #d9d9d9;
    --crd_body_bg: #efefef;

    .card__head {
        background-color: var(--crd_head_bg);
        padding: 10px 15px;
        display: flex;
        gap: 10px;

        &:empty {
            display: none;
        }

        .titles {

            h4,
            p {
                margin: 0;
                line-height: 100%;
            }

            p {
                font-size: 13px;
            }
        }
    }

    .card__body {
        background-color: var(--crd_body_bg);
        padding: 15px 25px;
    }
}