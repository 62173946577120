body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: 'Roman';
} */

@font-face {
  font-family: 'Roman';
  src: url('../public/assets/fonts/Roman-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Roman';
  src: url('../public/assets/fonts/Roman-Normal.ttf');
  font-weight: 500;
}

/* CAIRO */
@font-face {
  font-family: 'Cairo';
  src: url('../public/assets/fonts/Cairo/Cairo-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Cairo';
  src: url('../public/assets/fonts/Cairo/Cairo-Regular.ttf');
  font-weight: 500;
}

body.ltr {
  font-family: 'Roman' !important;
}

body.rtl {
  font-family: 'Cairo' !important;
}



:root {
  --primary-color: #C71513;
  --secondary-color: #161616;
}


::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  outline: none;
  border-radius: 100px;
}