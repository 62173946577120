#RadioButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .wrapper {
        display: inline-flex;
        flex-direction: column;
        width: 100%;

        &>label {
            color: #161616;
            padding-bottom: 4px;

            em {
                color: red;
                margin: 0 5px;
                font-style: normal;
            }
        }

        .buttons {
            display: inline-flex;
            gap: 10px;
            height: 41px;
            align-items: center;

            .radiobtn {
                display: inline-flex;
                flex-direction: row-reverse;
                gap: 10px;
                accent-color: #000;
            }
        }
    }

    small {
        color: red;
    }
}