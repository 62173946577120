&._1 {
  label {
    color: var(--secondary-color);
    padding-bottom: 7px;
    font-size: 14px;
    font-weight: 400;
  }

  .buttons {
    display: inline-flex;
    gap: 10px;
    height: 41px;
    align-items: center;

    label {
      padding-bottom: 2px;
    }

    .radiobtn {
      accent-color: var(--primary-color) !important;
    }
  }
}

&._2 {
  .label {
    font-size: 20px;
  }

  .buttons {
    justify-content: flex-start;
    margin: 0;
  }

  .radiobtn {
    accent-color: var(--primary-color) !important;
  }
}