#ArrayCollector {
    .header {
        display: flex;

        p {
            flex-grow: 1;
        }
    }

    .array_collector_item {
        display: flex;
        align-items: center;
        gap: 15px;
        padding-left: 10px;

        &:nth-child(even) {
            background-color: rgb(240, 240, 240);
        }
    }

    .extra_icon {
        cursor: pointer;
    }

    .btn_delete {
        cursor: pointer;
    }

    .btn_add {
        cursor: pointer;
        margin-top: 10px;
        height: 30px;
        border-radius: 4px !important;
    }
}