#Image {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;

    &>p {
        margin: 0;
    }

    img {
        width: 35px;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border: 1px solid gainsboro;
    }
}