:root {
  --navbar_width: 250px;
  --header_height: 100px;
}

div#Layout {

  display: flex;
  height: 100%;
  max-width: 1920px;

  &>aside {
    background-color: gainsboro;
    width: var(--navbar_width);
    position: fixed;
    height: 100vh;
    z-index: 1;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &>div {
    width: calc(100% - var(--navbar_width));
    margin-left: var(--navbar_width);

    #App[dir="rtl"] & {
      margin-right: var(--navbar_width);
      margin-left: 0;
    }

    &>header {
      background-color: darkgrey;
      height: var(--header_height);
      position: sticky;
      top: 0;
      width: calc(100vw - var(--navbar_width));
      z-index: 2;
    }

    &>main {
      padding: 1px;
      height: calc(100% - var(--header_height));
      position: relative;

      .css_oth {
        width: calc(100% - var(--navbar_width));
      }
    }
  }

  &.t1 {
    &>div {
      margin-right: 0 !important;
      width: 100%;

      &>header {
        margin-right: 0 !important;
      }
    }

    &>div>main .css_oth {
      width: 100% !important;
    }
  }
}