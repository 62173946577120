& {

  a {
    img.toggle_icon {
      width: 11px;
    }

    &.active.toggle_on {

      &+menu {
        // background-color: #e3e3e3;
        // border-radius: 10px;

        a {
          // padding: 10px;
          text-transform: capitalize;
          font-size: 13px;
          padding: 5px 40px;
          padding-left: 35px;

          img.menu_icon {
            margin-left: 10px;
            width: 15px;
          }

          &.active {
            background-color: #fff;
            color: var(--primary-color);
            border-radius: 10px;
            p{
              color: var(--primary-color);
            }

            img.menu_icon {
              filter: brightness(0) saturate(100%) invert(17%) sepia(50%) saturate(5542%) hue-rotate(356deg) brightness(96%) contrast(91%);
            }
          }
        }
      }
    }
  }

  &.l_1 {
    &>a {
      margin-bottom: 20px;
      padding: 5px 30px;
      font-size: 15px;
      &.active {
        margin-bottom: 10px;
        background: #fff4f4;
        padding: 10px 30px;

        &+menu {
          margin-bottom: 20px;
        }
      }
    }
  }
}