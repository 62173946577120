#ToolTip {
    position: relative;

    .ToolTip_anchor {
        cursor: pointer;
    }

    .ToolTip_float {
        position: absolute;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 0px 15px -3px rgba(0, 0, 0, 0.3), 0 4px 6px -4px rgba(0, 0, 0, 0.3);
        left: 0;
        top: calc(100% + 10px);
    }
}