#FilterBox {
    position: relative;
    padding-top: 10px;

    .buttons {
        display: flex;
        margin-top: 28px;
    }

    .more_buttons {
        position: absolute;
        left: 20px;
        top: 20px;
        display: inline-flex;
        gap: 10px;

    }

    .toggle_icon {
        border: 1px solid #c71612;
        padding: 7px;
        border-radius: 5px;
        width: 30px;
        height: 30px;
        opacity: 1;
        cursor: pointer;
        filter: brightness(0) saturate(100%) invert(22%) sepia(87%) saturate(3434%) hue-rotate(351deg) brightness(77%) contrast(102%);
    }

    &.toggle_off {
        padding: 0;
        height: 70px;

        #Flex {
            display: none;
            overflow: hidden;
        }

        .export_button {
            // display: none;
        }

        & .toggle_icon {
            display: block;
            // position: absolute;
            // left: -40px;
            // top: 20px;
        }
    }
}