#TranslationManagement {
    table {
        margin: 50px 0;

        th {
            &.verified {
                display: inline-flex;
                gap: 5px;

                img {
                    cursor: pointer;
                }
            }

            &.action {
                text-align: center;
            }
        }

        td {
            &.verified {
                text-align: center;

                button {
                    border: none;
                    background-color: #4ac7ba;
                    border-radius: 5px;
                    font-size: 10px;
                    color: #fff
                }
            }

            &.action {
                text-align: center;
                cursor: pointer;
            }
        }
    }
}