html {
    height: 100%;
    display: flex;
    flex-direction: column;
    scrollbar-gutter: stable;
}

body {
    --main_bg: #fff;
    flex-grow: 1;
}

#root,
#water-mark-wrapper,
#App {
    height: 100%;
}

#App {
    background-color: var(--main_bg);

    p {
        margin: 0;
    }
}

#App {
    @import '../common/styles/__App.scss';
}

#ActionButtons {
    &.css-acb {
        @import '../common/styles/ActionButtons.scss';
    }
}

#Actions {
    &.css-act {
        @import '../common/styles/Actions.scss';
    }
}

#AttachmentsEyeView {
    &.css-aev {
        @import '../common/styles/AttachmentsEyeView.scss';
    }
}

#AttachmentsPreview {
    &.css-atp {
        @import '../common/styles/AttachmentsPreview.scss';
    }
}

#Breadcrumb {
    &.css-brc {
        @import '../common/styles/Breadcrumb.scss';
    }
}

#Button {
    &.css-btn {
        @import '../common/styles/Button.scss';
    }
}

#Card {
    &.css-crd {
        @import '../common/styles/Card.scss';
    }
}

#Checkbox {
    &.css-cbx {
        @import '../common/styles/Checkbox.scss';
    }
}

#Choose {
    &.css-cho {
        @import '../common/styles/Choose.scss';
    }
}

#DatePick {
    &.css-dtp {
        @import '../common/styles/DatePick.scss';
    }
}

#DateViewer {
    &.css-dtv {
        @import '../common/styles/DateViewer.scss';
    }
}

#Notifications {
    &.css-ntf {
        @import '../common/styles/Notifications.scss';
    }
}

#Div {
    &.css-div {
        @import '../common/styles/Div.scss';
    }
}

#EntriesCounter {
    &.css-enc {
        @import '../common/styles/EntriesCounter.scss';
    }
}

#Files {
    &.css-fls {
        @import '../common/styles/Files.scss';
    }
}

#FileUpload {
    &.css-fup {
        @import '../common/styles/FileUpload.scss';
    }
}

#Flex {
    &.css-flx {
        @import '../common/styles/Flex.scss';
    }
}

#FooterButtons {
    &.css-ftb {
        @import '../common/styles/FooterButtons.scss';
    }
}

#Grid {
    &.css-grd {
        @import '../common/styles/Grid.scss';
    }
}

#Image {
    &.css-img {
        @import '../common/styles/Image.scss';
    }
}

#InfoIcon {
    &.css-ifi {
        @import '../common/styles/InfoIcon.scss';
    }
}

#Input {
    &.css-inp {
        @import '../common/styles/Input.scss';
    }
}

#LabelValue {
    &.css-lbv {
        @import '../common/styles/LabelValue.scss';
    }
}

#Layout {
    &.css-lyt {
        @import '../common/styles/Layout.scss';
    }
}

#List {
    &.css-lis {
        @import '../common/styles/_List.scss';
    }
}

#Logs {
    &.css-log {
        @import '../common/styles/Logs.scss';
    }
}

#Modal {
    &.css-mdl {
        @import '../common/styles/Modal.scss';
    }
}

#ModalContent {
    &.css-mdc {
        @import '../common/styles/ModalContent.scss';
    }
}

#NavBar {
    &.css-nvb {
        @import '../common/styles/NavBar.scss';
    }
}

#Pagination {
    &.css-pgn {
        @import '../common/styles/Pagination.scss';
    }
}

#ProgressBar {
    &.css-pgb {
        @import '../common/styles/ProgressBar.scss';
    }
}

#RadioButtons {
    &.css-rdb {
        @import '../common/styles/RadioButtons.scss';
    }
}

#FilterBox {
    &.css-flb {
        @import '../common/styles/FilterBox.scss';
    }
}

#Page {
    &.css-pag {
        @import '../common/styles/Page.scss';
    }
}

#Page_Header {
    &.css-pgh {
        @import '../common/styles/Page_Header.scss';
    }
}

#Page_Body {
    &.css-pgb {
        @import '../common/styles/Page_Body.scss';
    }
}

#Page_Footer {
    &.css-pgf {
        @import '../common/styles/Page_Footer.scss';
    }
}

#Section {
    &.css-sct {
        @import '../common/styles/Section.scss';
    }
}

#Select {
    &.css-slc {
        @import '../common/styles/Select.scss';
    }
}

#Slider {
    &.css-sld {
        @import '../common/styles/Slider.scss';
    }
}

#StatusBox {
    &.css-stb {
        @import '../common/styles/StatusBox.scss';
    }
}

#SupportedFormats {
    &.css-spf {
        @import '../common/styles/SupportedFormats.scss';
    }
}

#Table {
    &.css-tbl {
        @import '../common/styles/Table.scss';
    }
}

#CommentsViewer {
    &.css-tcv {
        @import '../common/styles/CommentsViewer.scss';
    }
}

#TextArea {
    &.css-txr {
        @import '../common/styles/TextArea.scss';
    }
}

#Title {
    &.css-ttl {
        @import '../common/styles/Title.scss';
    }
}

#TitleBar {
    &.css-tlb {
        @import '../common/styles/TitleBar.scss';
    }
}