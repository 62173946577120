div#ProgressBar {

    --pgb-color: c9c9c9;

    width: 100%;
    padding: 10px 0;

    .progress-bar {
        background-color: var(--pgb-color);
    }
}