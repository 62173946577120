&._1 {
    textarea {
        border-radius: 7px;
        background-color: inherit;
        border: none;
    }
}

&._2 {
    label {
        padding-bottom: 7px;
        font-size: 14px;
        font-weight: 400;
    }

    textarea {
        border: 1px solid #cccccc;
        font-size: 14px;
        padding: 20px;
        border-radius: 7px;
        background-color: inherit;
    }

}