& {
    text-shadow: 0 0 1px;
}

&._1 {
    margin-top: 10px;

    span.content span {
        padding: 4px;
    }

    img.file {
        height: 55px;
        width: 55px;
    }
}