menu#NavBar {

    --nvb_active_menu_color: blue;
    overflow: auto;
    height: 100%;
    margin-top: 0;
    list-style: none;
    padding: 0px;

    a {
        color: #555555;
        display: list-item;
        list-style: none;
        position: relative;
        text-decoration: none;
        display: flex;

        #App[dir="rtl"] &[lang="en"] {
            direction: ltr;
            text-align: right;
            justify-content: flex-end;

            img.menu_icon {
                order: 1;
            }
        }

        p {
            display: flex;
            // flex-direction: row-reverse;
            gap: 10px;
            direction: rtl;
            color: #212529;
        }

        img.menu_icon {
            width: 20px;
            margin-left: 10px;
            opacity: 0.7;
            // filter: brightness(0) saturate(100%) invert(45%) sepia(0%) saturate(0%) hue-rotate(240deg) brightness(102%) contrast(90%);
        }

        img.toggle_icon {
            width: 10px;
            position: absolute;
            left: 25px;
            top: 14px;
            filter: brightness(0) saturate(100%) invert(45%) sepia(0%) saturate(0%) hue-rotate(240deg) brightness(102%) contrast(90%);
        }

        &+menu {
            height: 0;
            overflow: hidden;
            padding: 0;
            margin: 0;

            a.active {
                background-color: #eee;
            }
        }
    }

    a.active.toggle_on {
        transition: all .5s ease-in-out;

        img.toggle_icon {
            rotate: 180deg;
            top: 18px;
        }

        &+menu {
            height: 100%;
            transition: all .5s ease-in-out;
            animation: fadeIn 1s;

            @keyframes fadeIn {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }
    }
}