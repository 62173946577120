#Files {
    label {
        color: #161616;
        font-size: 14px;
        margin-bottom: 7px;
    }

    span.files_content {
        display: flex;
        gap: 15px;
        width: 100%;
        flex-wrap: wrap;

        span {
            position: relative;
            background-color: #e2e2e2;
            padding: 7px 5px 6px;
            border-radius: 5px;
            display: inline-flex;

            a {
                display: inline-flex;
            }

            &.image img.file {
                border-radius: 5px;
                object-fit: cover;
            }

            p {
                position: absolute;
                text-align: center;
                bottom: -30px;
                right: 0;
                width: 100%;
            }

            img.close {
                position: absolute;
                top: -8px;
                right: -8px;
                cursor: pointer;
                width: 23px;
            }
        }
    }

    img.file {
        height: 55px;
        width: 55px;

        &.pre {
            cursor: pointer;
        }
    }
}

#Files_preview {
    display: flex;
    align-items: center;
    justify-content: center;

    img,
    audio,
    video,
    object {
        width: 200px;
        max-height: 80vh;
        object-fit: cover;
    }

    audio::-webkit-media-controls-play-button {
        background-color: red;
        border-radius: 100px;
    }

    object {
        min-height: 60vh;
    }
}