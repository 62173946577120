ul#List {

    --lis_bullet_size: 10px;
    --lis_bullet_gap: 10px;
    --lis_gap: 0px;

    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: var(--lis_gap);

    &:empty {
        display: none;
    }

    li {
        position: relative;

        &:has(img.•) {
            margin-right: calc(var(--lis_bullet_gap) * 2 + var(--lis_bullet_size));
        }

        img.• {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: calc((var(--lis_bullet_gap) + var(--lis_bullet_size)) * -1);
            width: var(--lis_bullet_size);
        }

        img.•~::before {
            content: '';
            position: absolute;
            top: calc(var(--lis_gap) * -1);
            right: calc((var(--lis_bullet_gap) + (var(--lis_bullet_size) / 2) + 1px) * -1);
            display: block;
            height: 47%;
            width: 1px;
            background-color: gainsboro;
        }

        img.•~::after {
            content: '';
            position: absolute;
            bottom: calc(var(--lis_gap) * -1);
            right: calc((var(--lis_bullet_gap) + (var(--lis_bullet_size) / 2) + 1px) * -1);
            display: block;
            height: 47%;
            width: 1px;
            background-color: gainsboro;
        }

        &:first-of-type img.•~::before {
            display: none;
        }

        &:last-of-type img.•~::after {
            display: none;
        }
    }
}