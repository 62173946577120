#Insta_Card {
    width: 250px;
    height: 350px;
    border: 1px solid gainsboro;
    display: flex;
    flex-direction: column;
    direction: ltr;
    position: relative;

    .header {
        display: flex;
        border-bottom: 1px solid gainsboro;
        padding: 5px;

        .logo {
            width: 35%;
            margin: 0 auto;
        }
    }

    .profile {
        display: flex;
        align-items: center;
        border-bottom: 1px solid gainsboro;
        padding: 5px 12px;
        gap: 10px;

        .profile_pic {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid gainsboro;
        }
    }

    .content {
        flex-grow: 1;
        overflow: hidden;
        background-color: #eee;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        video {
            width: 100%;
            height: 100%;
        }

        div.invalid {
            color: red;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .options {
        border-top: 1px solid gainsboro;
        padding: 10px;
        padding-bottom: 5px;

        .icons {
            display: flex;
            gap: 10px;
            margin-bottom: 5px;

            img {
                width: 18px;
            }
        }

        .more_info {
            font-size: 8px;
        }
    }

    .profile_name {
        font-size: 10px;

        .verified {
            width: 10px;
            margin-left: 5px;
        }
    }

    .close_icon {
        position: absolute;
        width: 20px;
        background-color: #dc0b09;
        border-radius: 50%;
        padding: 4px;
        top: 5px;
        left: 5px;
        cursor: pointer;
    }
}