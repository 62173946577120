#ReportCounts {

    margin: 60px 0;

    div.item {
        display: flex;
        background-color: #fff;
        box-shadow: 4px 1px 10px 2px #e9e9e9;
        padding: 15px;
        padding-bottom: 18px;
        justify-content: space-between;
        border-radius: 10px;

        &>span {
            flex-grow: 0;
            width: 50px;

            img {
                width: 40px;
                margin-bottom: 20px;
            }

            p.title {
                text-wrap: nowrap;
            }
        }

        P.count {
            font-size: 25px;
            position: relative;
            top: 20px;
        }
    }
}