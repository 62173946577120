&._fileViewer {
    background-color: red;

    img {
        width: 100%;
    }

    video {
        width: 100%;
    }

    object {
        width: 100%;
    }
}