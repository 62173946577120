div#FileUpload {


    p.label {
        margin: 0;

        em {
            color: red;
            margin: 0 5px;
            font-style: normal;
        }
    }

    label {
        border: 1px solid;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        border-radius: 5px;
        color: #474747;
        cursor: pointer;
        text-wrap: nowrap;

        img {
            width: 18px;
        }

        &.reverse {
            flex-direction: row-reverse;
        }
    }

    &.drag_drop {
        label {
            width: 100%;
            background-color: inherit;
            border: 1px dashed;
        }
    }

    &.limited {
        .lim_btns {
            display: flex;
            gap: 10px;

            #Files {
                margin: 0;
            }

            .lim_item {
                width: 53px;
                height: 53px;

                img {
                    object-fit: cover;
                }
            }
        }
    }

    &.button {
        label {
            padding: 8px 20px;
        }
    }

    small.error {
        display: block;
        color: red;
    }

    #Files {
        margin-top: 20px;
    }
}