#Samples {
    padding: 1px 20px;
    margin-bottom: 500px;

    h1 {
        font-size: 30px;
        font-weight: bold;
        color: rgb(145, 145, 145);
        margin: 40px 0 10px;
        text-align: center;
    }

    &>table {

        border-spacing: 25px 15px;
        border-collapse: separate;

        thead:first-child h3 {
            margin-top: 10px;
        }

        &>thead {

            &>tr {
                &>th {
                    h3 {
                        text-align: left;
                        font-size: 18px;
                        text-decoration: underline;
                        color: red;
                        margin-top: 100px;
                        font-weight: bold;

                        #App[dir="rtl"] & {
                            text-align: right;
                        }
                    }
                }
            }
        }

        &>tbody {
            &>tr {
                &>td {
                    &:first-child {
                        vertical-align: middle;
                        text-align: center;
                        height: 42px;
                        padding: 0 10px;
                        color: #fff;
                        font-weight: bold;
                        background-color: #c9c9c9;
                        border-radius: 100px;
                    }

                    &:last-child {
                        padding-right: 10;
                        width: 100%;
                    }
                }
            }
        }
    }
}