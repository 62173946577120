.Dashboard_super_admin {

    #Page_Body {
        display: grid;
        gap: 20px;
        grid-template-rows: 120px 230px 120px 220px auto 500px 1fr;
        grid-template-columns: repeat(9, 1fr);
        grid-template-areas:
            "a a a a a a g g g"
            "p p p p p p g g g"
            "p p p p p p g g g"
            "q q q q q q g g g"
            "n n n n n n n n n"
            "h h h h h h i i i"
            "j j j j j j j j j";

        .board_a {
            grid-area: a;
        }

        .board_b {
            grid-area: b;
        }

        .board_c {
            grid-area: c;
        }

        .board_d {
            grid-area: d;
        }

        .board_e {
            grid-area: e;
        }

        .board_f {
            grid-area: f;
        }

        .board_g {
            grid-area: i;
        }

        .board_h {
            grid-area: h;
        }

        .board_i {
            grid-area: g;
        }

        .board_j {
            grid-area: j;
        }

        .board_k {
            grid-area: k;
        }

        .board_l {
            grid-area: l;
        }

        .board_m {
            grid-area: m;
        }

        .board_p {
            grid-area: p;
        }

        .board_q {
            grid-area: q;
        }

        &>div {
            background-color: #fff;
            border-radius: 5px;
            display: flex;
            align-items: center;
            gap: 20px;
            padding: 20px;
            box-shadow: 0px 3px 24px #0000000D;
        }

        .board_b,
        .board_c,
        .board_d,
        .board_k,
        .board_l,
        .board_m {

            .content {
                width: 100%;

                img {
                    align-self: flex-start;
                    width: 40px;
                }

                .title,
                .count {
                    font-size: 15px;
                }

                .detail {
                    font-size: 11px;
                    margin-top: 20px;
                    text-align: left;
                    direction: ltr;

                    img.growth {
                        width: 15px;
                        margin-right: 20px;

                        &.dn {
                            rotate: -90deg;
                        }
                    }
                }
            }
        }

        .board_e,
        .board_f {
            flex-direction: column;
        }

        .board_g {
            flex-direction: column;
        }

        .board_i {
            flex-direction: column;

            .on {
                position: absolute;
                left: 10px;
            }
        }

        .board_j {
            flex-direction: column;
            position: relative;

            .content {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;
                align-items: center;
                // height: 400px;
                width: 100%;
            }
        }

        .board_h {
            flex-direction: column;
        }

        .board_h,
        .board_g,
        .board_i,
        .board_j {
            .head {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: calc(100% + 40px);
                border-bottom: 1px solid gainsboro;
                padding: 0 20px;
                padding-bottom: 10px;

                .view_all {
                    color: var(--primary-color);
                    font-size: 12px;
                    cursor: pointer;
                }
            }
        }

        .board_n {
            // div {
            //     display: flex;
            //     gap: 10px;
            // }
            padding: 0;
            display: block;

            grid-area: n;
            background-color: inherit;
            box-shadow: none;

            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-row: inherit;
        }
    }

    #duration {
        margin-right: auto;
        margin-top: auto;
        background: #C716120B 0% 0% no-repeat padding-box;
        border: 1px solid #3E3E3E;
        border-radius: 8px;
        opacity: 1;
        padding: 10px;
        font-size: 10px;

        .title {
            color: #9b9b9b;
            display: inline-block;
            font-size: 10px;
        }

        .range {
            font-size: 10px;
        }
    }

    .board_p, .board_q{
        padding: 0 !important;
        .main__grids{
            .title{
                padding: 20px;
                h2{
                    font-size: 18px;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }

        .dashboard__grids{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            .gridItem{
                border: 1px solid #D9D9D9;
                padding: 20px;
                transition: all .4s;
                &:hover{
                    background-color: #c7161313;
                    cursor: pointer;
                    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                    transition: all .4s;
                    h2{
                        font-size: 17px;
                        transition: all .2s;
                    }
                    h3{
                        font-size: 2rem;
                        transition: all .4s;
                    }
                }
                &:nth-child(6),&:nth-child(7),&:nth-child(8), &:nth-child(9), &:nth-child(10){
                    border-bottom: 0;
                }
                &:first-child{
                    border-right: 0;
                }
                &:nth-child(6){
                    border-right: 0;
                }
                &:nth-child(5){
                    border-left: 0;
                }
                &:nth-child(10){
                    border-left: 0;
                }
                .counter{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px 0;
                    padding-top: 0;
                    min-height: 50px;
                    img{
                        height: 35px;
                    }
                }
                h2{
                    font-size: 16px;
                    opacity: 73%;
                    transition: all .2s;
                    min-height: 30px;
                    // min-height: 38px;
                }
                h3{
                    margin-bottom: 0;
                    transition: all .2s;
                }
                p{
                    color: #161616;
                    opacity: 58%;
                }
            }
        }
    }
    .board_q{
        height: 220px;
        .dashboard__grids{
            grid-template-columns: repeat(6, 1fr);
            .gridItem{
                border-bottom: 0;
                &:nth-child(5){
                    border-left: 1px solid #D9D9D9;
                    border-bottom: 0;
                }
                &:nth-child(6){
                    border-left: 0;
                    // border-bottom: 1px solid #D9D9D9;
                }
            }
        }
    }
}