#Slider {
    position: relative;
    height: fit-content;
    width: fit-content;

    &>.arrow {
        position: absolute;
        top: 50%;
        translate: 0 -50%;
        cursor: pointer;
        background-color: #fff;
        padding: 10px;
        z-index: 5;
        width: 30px;
        aspect-ratio: 1 / 1;

        &.left {
            transform: rotate(90deg);
            left: 0;
        }

        &.right {
            transform: rotate(-90deg);
            right: 0;
        }
    }

    &>.content {
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        position: relative;

        #App[dir="rtl"] & {
            flex-direction: row-reverse;
        }

        &>.slider_item {
            position: absolute;
            width: 100%;
            height: 100%;
            user-select: none;
            flex-shrink: 0;
            // opacity: 0;

            &.active {
                opacity: 1;
            }
        }

        &.video_playing {
            align-items: center;
            background-color: #1f1f1f;

            .slider_item {
                height: auto;

                video {
                    height: auto;
                }
            }
        }
    }

    &.type {

        &_1 {
            &>.content {
                user-select: none;
                gap: 20px;

                &>.slider_item {
                    transform: translateX(100%);
                    transition: transform .5s;

                    &.active {
                        transform: translateX(0%);
                    }
                }
            }
        }

        &_2 {
            &>.content {
                &>.slider_item {
                    transition: all 1s;
                    display: none;

                    &.active_1 {
                        transform: translateX(-100%);
                        display: block;
                    }

                    &.active {
                        transform: translateX(0%);
                        display: block;
                    }

                    &.active__1 {
                        transform: translateX(100%);
                        display: block;
                    }
                }
            }

        }
    }
}