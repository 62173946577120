& {
    background-color: #fff;
    border-radius: 10px;

    .content {
        padding: 30px;

        h3 {
            margin-bottom: 15px;

            #App[dir="rtl"] &[lang="en"] {
                direction: ltr;
                text-align: right;
            }
        }
    }

    .close_btn {
        border: 1px solid rgb(172, 172, 172);
        border-radius: 50%;
        padding: 5px;
        width: 30px;
    }

    &._1 {
        h3 {
            margin: 30px 0 20px;
        }
    }
}