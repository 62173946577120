&._1 {
    // box-shadow: 0px 10px 20px #0000000D;
    border-radius: 10px;
    border-bottom: 1px solid #e0e3e8;
    margin-bottom: 24px;

    img.toggleIcon {
        width: 15px;
    }

    &>div.header {
        background-color: #fff;
        border-bottom: 1px solid #00000014;
        padding: 0 20px;
        padding-top: 20px;

        h3 {
            font-weight: 400;
            font-size: 16px;
            padding-bottom: 10px;
            position: relative;
            padding-left: 0;
            border-bottom: 1px solid red;
            margin-right: 40px;
        }

            .toggleIcon{
                position: relative;
                top: -8px;
            }
        
    }

    section.tb_show {
        border: none;
        background-color: #fff;
        padding: 20px 40px;
    }
}