& {
    .header {
        p.count {
            background-color: var(--primary-color);
            color: #fff;
        }
    }

    ul {
        &::-webkit-scrollbar-thumb {
            background-color: var(--primary-color);
        }
    }
}