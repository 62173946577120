.Edit_Translation {
    h6 {
        text-align: center;
    }
}

#PopupContent {
    &.fileViewer {
        #m_close_btn {
            left: 7px;
            top: 7px;
        }

        #Button {
            position: absolute;
            right: 40px;
            top: 40px;
        }

        &>div {

            .file-prev {
                width: 100%;
                border-radius: 7px;
            }

            audio.file-prev {
                width: 350px;
                margin-top: 50px;
            }

        }

        p.name {
            font-size: 14px;
            text-align: center;
            margin-top: 15px;
        }
    }
}

#EditAlternatives {
    h3 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .altr_item {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 10px;

        .sl_no {
            width: 20px;
            flex-grow: 0;
            flex-shrink: 0;
        }

        #Input {
            input {
                height: 30px;
            }

            small#error {
                display: none;
            }

            &.error {
                input {
                    border-color: red;
                }
            }
        }

        #Select {
            margin-top: 0 !important;
            height: 30px;

            .css-13cymwt-control,
            .css-t3ipsp-control {
                height: auto;
                min-height: 30px;
            }

            .css-qbdosj-Input {
                padding: 0;
            }

            .css-1fdsijx-ValueContainer {
                height: 28px;
            }

            .css-1dimb5e-singleValue {
                padding: 0 10px !important;
            }
        }

        .altr_delete,
        .add_icon {
            width: 20px;
            cursor: pointer;
        }

        .add_icon {
            position: relative;
            left: 5px;
            border: 1px solid;
            border-radius: 5px;
            padding: 2px;
        }
    }

    .errors {
        font-size: 14px;
        color: red;
        display: flex;
        flex-direction: column;
    }
}

#FindCustomer {

    .action_description {
        direction: ltr;
        margin: 0;
        padding: 0;
        margin-top: 15px;

        li {
            padding-left: 10px;
            font-size: 13px;
            line-height: 120%;
            margin-top: 5px;

            &::marker {
                content: '*';
            }
        }
    }

    .head_title {
        text-align: center;
        font-size: 18px;
    }

    table {
        width: 100%;
    }

    thead {
        border-bottom: 2px solid #ffc9c9;
        background-color: #fff6f5;
    }

    th {
        text-align: center;
        font-weight: normal;
        // background-color: #e9e9e9;
        padding: 7px 0;

        &:first-child {
            border-top-right-radius: 7px;
        }

        &:last-child {
            border-top-left-radius: 7px;
        }
    }

    td {
        padding: 5px 10px;
        // &.entered {
        //     color: #0d6efd;
        // }

        // &.existing {
        //     color: #008000;
        // }

    }

    tr.customer_gender {

        td.entered,
        td.existing {
            text-transform: capitalize;
        }
    }

    tr {
        border-bottom: 1px solid #e9e9e9;

        &:nth-child(even) {
            background-color: #f9f9f9;
        }

        &.customer_country_code,
        &.customer_alternate_contact {

            .entered,
            .existing {
                direction: ltr;
                text-align: right;
            }
        }
    }

    p.qs {
        #App[dir="rtl"][lang="en"] & {
            direction: ltr;
            text-align: right;
        }
    }
}

#ActionConfirmation {

    div {
        text-align: center;
        padding-top: 15px;
    }

    span.highlight {
        color: red;
        font-size: 20px;
        margin: 0 5px;
    }

    p {
        font-size: 18px;

        &.st {
            display: inline;
        }
    }

    #App[dir="rtl"][lang="en"] & {
        direction: ltr;
    }
}

#DismissMessage {
    p {
        margin-top: 15px !important;
        font-size: 18px;
        text-align: center;
    }

    #App[dir="rtl"][lang="en"] & {
        direction: ltr;
    }
}

#Confirm {
    h3 {
        font-size: 24px;

        #App[dir="rtl"][lang="en"] & {
            direction: ltr;
            text-align: right;
        }
    }
}

#Volunteer {
    #Phone {
        width: auto;

        #Input {
            width: auto !important;
        }

        #Select {
            width: 120px;
        }
    }
}

#Add_Edit_IncidentType {
    .add-sub-types {
        display: flex;
        gap: 10px;
        margin-bottom: 15px;

        #Button {
            font-size: 20px;
            max-height: 45px;
        }
    }

    table.sub-incident-type {
        width: 100%;
        font-size: 13px;

        tr {
            td {
                padding: 5px 0;
                padding-right: 10px;
            }

            &:nth-child(odd) {
                td {
                    background-color: rgb(240, 240, 240);
                }
            }
        }

        .en {
            width: 226px;
        }

        .delete {
            img {
                margin-right: 17px;
                cursor: pointer;
            }
        }
    }
}