#Phone {
    display: flex;
    min-width: 300px !important;

    .css-1nmdiq5-menu {
        width: max-content;
    }

    .css-15lsz6c-indicatorContainer,
    .css-1xc3v61-indicatorContainer {
        display: none;
    }

    .css-1fdsijx-ValueContainer {
        padding: 0;
    }

    .css-13cymwt-control,
    .css-t3ipsp-control {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border: 1px solid #d9d9d9 !important;
        border-right: none !important;
    }

    .css-d7l1ni-option,
    .css-10wo9uf-option,
    .css-tr4s17-option,
    .css-1dimb5e-singleValue {
        padding: 3px 10px !important;
        height: auto !important;

        p.cntr_flag_cd {
            direction: rtl;

            img {
                width: 30px;
                height: 20px;
                margin-left: 10px;
                object-fit: cover;
            }

            p.country_code {
                display: inline;
                font-style: normal;
                color: #000;
                direction: ltr;
            }
        }
    }

    #Select {
        width: 200px;
    }

    #Input {
        input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}