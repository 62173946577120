&._1 {

    --lis_bullet_size: 30px;
    --lis_bullet_gap: 30px;
    --lis_gap: 20px;

    li {
        display: flex;
        gap: 30px;
        box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
        box-shadow: 0px 10px 20px #0000000D;
        padding: 20px 30px;
        margin-left: 40px;

        .user_img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }

        h6 {
            font-size: 16px;
            margin-bottom: 10px;
        }

        .title {
            td {
                color: rgb(167, 128, 0);
                font-size: 14px;
                padding-top: 10px;
                padding-right: 0;
                font-weight: 600;
            }
        }

        .date {
            font-weight: 400;
            font-size: 12px;
            opacity: 80%;
            margin-bottom: 10px;
        }

        .comment {
            font-size: 14px;
        }
    }
}

&._2 {
    list-style: disc;
    margin-bottom: 10px;
    margin-top: 20px;
    padding-right: 40px;

    li {
        box-shadow: none;
        padding: 0;
        margin: 0;
        display: list-item;
        font-size: 14px;
    }
}

&._3 {
    padding: 0;
    overflow: auto;
    margin: 0;
    width: 100%;

    li {
        display: flex;
        gap: 20px;
        border: 1px solid gainsboro;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;

        img {
            width: 40px;
        }

        div {
            width: 100%;

            .title {
                color: var(--primary-color);
                font-size: 14px;
                margin-bottom: 10px;
            }

            .desc {
                font-size: 12px;
            }
        }

        .at {
            font-size: 12px;
            margin-top: 10px;
        }

        .on {
            margin-top: 3px;
            font-size: 11px;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &.__1 {
        li {
            padding: 30px;
        }
    }
}

&._4 {
    padding: 10px 0;
    overflow: auto;
    margin: 0;

    li {
        display: flex;
        margin-bottom: 25px;
        padding-left: 10px;
        padding-right: 20px;
        position: relative;
        gap: 20px;
        justify-content: space-between;

        .content {
            font-size: 14px;
        }

        .at {
            font-size: 12px;
            margin-top: 10px;
        }

        .on {
            margin-top: 3px;
            font-size: 11px;
        }

        &::before {
            content: '';
            position: absolute;
            top: -3px;
            right: 0px;
            display: inline-block;
            width: 7px;
            height: 7px;
            border-radius: 100px;
            background-color: var(--primary-color);
        }

        &::after {
            content: '';
            position: absolute;
            right: 3px;
            top: 12px;
            width: 1px;
            height: 100%;
            display: inline-block;
            border-left: 1px solid gainsboro;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &.__1 {
        li {
            border-bottom: 1px solid gainsboro;
            padding-bottom: 25px;
            margin-bottom: 25px;

            &::after {
                height: 60%;
            }
        }
    }
}

&._5 {
    flex-direction: row;
    gap: 10px;

    li {
        flex: 1 1 30%;
        // height: 100%;
        position: relative;

        .icon {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 25px;
        }

        .feed {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 3px;
            cursor: pointer;
        }
    }
}