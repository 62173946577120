#Dash_card {
    background-color: #fff;
    // display: inline-block;
    padding: 10px 20px;
    border-bottom: 4px solid #ba0013;
    // min-width: 270px;
    width: 100%;
    height: 150px;
    // margin: 4px;
    // border: 1px solid #e3e3e3;
    border-radius: 8px;
    cursor: pointer;

    .header {
        display: flex;
        align-items: center;
        padding-top: 20px;

        .title {
            h2 {
                margin-bottom: 0;
                font-size: 20px;
            }

            p {
                margin-bottom: 0;
                font-size: 16px;
                color: #50b050;
                padding-top: 2px;
            }
        }

        .icon {
            padding-left: 10px;
        }
    }

    .prevWeek {
        padding-top: 25px;

        p {
            text-align: left;
            font-size: 13px;
            color: #ba0013;
            // opacity: .5;
        }
    }
}