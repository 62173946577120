&._1 {
    --cbx_bg: transparent;

    span {
        input {
            accent-color: var(--primary-color);
            width: 18px;
            height: 18px;
        }

        label {
            font-weight: 400;
            font-size: 14px;
        }
    }

    &.disabled {
        color: #bdbdbd;
    }
}

&._2 {
    --cbx_bg: transparent;
    --toggle_button_margin: 2px;
    --toggle_switch_width: 35px;
    --toggle_switch_height: 20px;
    --toggle_switch_color_on: #C71513;
    --toggle_switch_color_off: gray;

    margin: 10px 0;

    #ToggleSwitch {
        &[data-checked="true"] {
            border: 2px solid var(--toggle_switch_color_on);
            background-color: transparent;

            span {
                background-color: var(--toggle_switch_color_on);
            }
        }

        &[data-checked="false"] {
            border: 2px solid var(--toggle_switch_color_off);
            background-color: transparent;

            span {
                background-color: var(--toggle_switch_color_off);
            }
        }

    }
}