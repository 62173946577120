#TitleBar {

    --tlb_border_radius: 8px;
    border-radius: var(--tlb_border_radius);

    &>div.header {
        background-color: #c4c4c4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-radius: var(--tlb_border_radius) var(--tlb_border_radius) 0 0;
        cursor: pointer;

        h3 {
            color: #373737;
            font-size: 16px;
            line-height: 24px;
            margin: 0;
            font-weight: 600;
        }

        div.actions {
            display: flex;
            gap: 15px;

            img.toggleIcon {
                color: #161616;
                cursor: pointer;
                rotate: 180deg;

                &.tb_collapse {
                    rotate: 0deg;
                }
            }
        }
    }

    section.tb_content {
        border: 1px solid #d9d9d9;
        border-top: none;
        border-radius: 0 0 var(--tlb_border_radius) var(--tlb_border_radius);
        padding: 20px;
        // overflow: hidden;

        &.tb_hide {
            border: none;
            overflow: hidden;
            height: 0;
            padding: 0;
        }
    }
}