&._1 {
  .wrap {
    input {
      background-color: transparent;
      border: 1px solid #cccccc;
      // box-shadow: #3e3e3e21 0px 1px 4px;
      font-size: 14px;
      // padding: 15px;
      border-radius: 8px;
      min-height: 42px;
    }
  }
}