div#TextArea {
    display: flex;
    flex-direction: column;

    label {
        em {
            color: red;
            margin: 0 5px;
            font-style: normal;
        }

        &>p {
            display: inline-block;
        }

        #App[dir="rtl"] &[lang="en"] {
            direction: ltr;
            text-align: right;
        }
    }

    textarea {
        height: 150px;
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        resize: none;
        direction: ltr;
        text-align: right;
        background-color: gainsboro;
        border: 1px solid #000;

        &:focus {
            outline: none;
        }
    }

    small.error {
        color: red;
    }
}