div#Flex {
    display: flex;
    column-gap: var(--fx_g);
    flex-wrap: wrap;
    margin-bottom: calc(var(--fx_g) * -1);

    &>* {
        flex: 1 1;
        min-width: var(--fx_cmw);
        flex-basis: calc(var(--fx_b) - var(--fx_g));
        margin-bottom: var(--fx_g);
    }

    .fx_x {
        margin-bottom: 0;
    }
}