span#StatusBox {

    p {
        margin: 0;
        text-align: center;
    }

    span.box {
        display: inline-block;
        text-align: center;
        color: white;
        border-radius: 5px;
        min-width: 100px;
        width: fit-content;
        padding: 4px 2px;
        font-size: 14px;
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--sbx-color);
        line-height: 100%;
    }
}