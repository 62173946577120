#PopupContent {
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
    width: 600px;
    position: relative;

    #m_close_btn {
        position: absolute;
        top: 20px;
        left: 20px;
        cursor: pointer;
    }
}