&._1 {

    .table_handler {
        #Input {
            input {
                &::placeholder {
                    color: #757575;
                }
            }
        }
    }

    .table_content {
        background-color: #fff;
        border-radius: 10px;
        border-bottom: 1px solid #e0e3e8;

        tr:not(tbody>tr:last-child) {
            border-bottom: 1px solid #EAEAEA;
        }

        thead {
            th {
                font-weight: normal;
                font-size: 14px;
                color: #161616;
                border: none;
                font-size: 13px;
                height: 55px;

                &.sort {
                    padding-right: 24px;

                    &:first-of-type {
                        padding-right: 54px;
                    }
                }

                &:first-of-type {
                    padding-right: 40px;

                    &.sort span {
                        right: 40px;
                    }
                }

                &:last-of-type {
                    padding-left: 40px;
                    position: sticky;
                    left: 0;
                    top: 0;
                    background: #fff;
                }
            }
        }

        tbody {
            tr {
                td {
                    border: none;
                    color: #161616db;
                    font-size: 13px;
                    height: 55px;

                    &:first-of-type {
                        padding-right: 40px;
                    }

                    &:last-of-type {
                        padding-left: 40px;
                        position: sticky;
                        left: 0;
                        top: 0;
                        background: #fff;
                    }

                    label {
                        font-weight: normal;
                        font-size: 13px;
                        color: #161616;
                    }
                }
            }
        }

        .sorter {
            top: 20px;
            right: 8px;
            position: absolute;

            &:after {
                background-image: url('../../../public/assets/icons/caret-down.svg');
                background-size: contain;
                display: inline-block;
                width: 17px;
                height: 17px;
                content: "";
                position: absolute;
                right: -4px;
                top: 4px;
            }

            &:before {
                background-image: url('../../../public/assets/icons/caret-up.svg');
                background-size: contain;
                display: inline-block;
                width: 17px;
                height: 17px;
                content: "";
                position: absolute;
                right: -4px;
                bottom: -13px;
            }
        }
    }

    &.__1 {
        .table_content {
            box-shadow: none;
        }
    }

    &.__2 {
        .table_handler {
            #Input {
                // margin-left: auto;
                margin-right: 10px;
            }

            #Select {
                margin-right: 10px;
                margin-left: auto;
            }
        }
    }
}

&._2 {
    .table_handler {
        display: none;
    }

    .sorter {
        display: none;
    }
}