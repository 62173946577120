&._1 {
    height: 100vh;
    width: 100vw;
    background-color: transparent;

    &>.arrow {
        border-radius: 50%;
        width: 32px;

        &.right {
            right: 25px;
        }

        &.left {
            left: 25px;
        }
    }

    &>.content {
        display: flex;
        justify-content: center;
        align-items: center;

        &>.slider_item {
            height: 70%;
            width: 60%;

            &.active_1 {
                transform: translateX(-100vw);
            }

            &.active__1 {
                transform: translateX(100vw);
            }

            &>.insta_post {
                height: 100%;
                background-color: #fff;
                border-radius: 20px;
                overflow: hidden;
                display: flex;

                &>.close_icon {
                    position: absolute;
                    left: 15px;
                    top: 15px;
                    width: 20px;
                    cursor: pointer;
                }

                .slider-caption {
                    width: 50%;
                    padding: 80px 40px;

                    .profile {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .profile_pic {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            border: 1px solid gainsboro;
                        }

                        .profile_name {
                            font-size: 14px;

                            .verified {
                                width: 12px;
                                margin-right: 5px;
                            }
                        }
                    }

                    .post-content {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

&._2 {
    height: 100%;
    width: 50%;

    &>.arrow {
        border-radius: 50%;
        width: 25px;
        padding: 8px;

        &.right {
            right: 10px;
        }

        &.left {
            left: 10px;
        }
    }

    div.slider_item {
        &.active_1 {
            transform: translateX(-100%);
        }

        &.active__1 {
            transform: translateX(100%);
        }
    }

    .insta_post_file {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}